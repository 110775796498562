import QuestionnaireForm from "../components/QuestionnaireForm"
import QuestionnaireSubmitted from "../components/QuestionnaireSubmitted"
// import QuestionnaireFormModal from "../components/items/QuestionnaireFormModal"
// import { setMessage } from "../features/flashMessageSlice"
// import { increase, decrease } from "../features/loadingSlice"
import {
    setPercentageActive,
    setPercentageEnv,
    selectPercentage,
} from "../features/percentageSlice"
import { submit } from "../features/questionnaireSlice"

import { selectUser } from "../features/userSlice"
import { Server } from "../server"
import {
    DEFAULT_TEST_PROFILE,
    User,
    Submission,
    Questionnaire as QuestionnaireData,
} from "../types"

import queryString from "query-string"
// import React, { useState, useEffect } from "react"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import useSWR from "swr"

const server = Server.getServer()

type Props = { questionnaireId: number }

type ValidationError = {
    message: string
    errors: ValidationErrorDetail[]
}

type ValidationErrorDetail = { key: string | null; message: string }

export default function QuestionnaireDetail(props: Props): React.ReactElement {
    const dispatch = useDispatch()
    const history = useHistory()
    const percentage = useSelector(selectPercentage)
    const user = useSelector(selectUser)
    const questionnaire = useQuestionnaire(props.questionnaireId)
    const saveQuestionnaire = JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")
    const submission =
        Object.keys(saveQuestionnaire).length !== 0 &&
        props.questionnaireId === saveQuestionnaire.questionnaireId
            ? saveQuestionnaire
            : useSubmission(user, props.questionnaireId, questionnaire)

    const [focusedItem, setFocusedItem] = useState<string | null>(null)

    // useEffect(() => {
    //     console.log("questionnaire 불러와짐")
    //     console.log(questionnaire)
    //     try {
    //         dispatch(increase())
    //     } finally {
    //         dispatch(decrease())
    //     }
    // }, [questionnaire])

    // history.push({
    //     // pathname: `/questionnaireList/${tid}`,
    //     search: `?submitted=true`,
    // })

    function handleSubmit(s: Submission) {
        if (!questionnaire) return
        const error = validate(s, questionnaire, percentage)

        if (error === null) {
            dispatch(submit(s))
            dispatch(setPercentageActive(false))
            dispatch(setPercentageEnv(false))
            history.push({
                // pathname: `/questionnaireList/${tid}`,
                search: `?submitted=true`,
            })
            // history.push(`/questionnaireList/${questionnaire.type}`)
            window.scrollTo(0, 0)
        } else {
            alert(error.message)
            // setFocusedItem(error.errors[0].key)
            setFocusedItem(null)
        }
    }

    function handleSaves() {
        alert("임시저장이 완료되었습니다.")
        // dispatch(setMessage("임시저장이 완료되었습니다."))
    }

    const isSubmitted = queryString.parse(location.search)["submitted"]

    return !user || !questionnaire || !submission ? (
        <></>
    ) : (
        <>
            <Helmet>
                <title>{questionnaire.name} | K2</title>
            </Helmet>
            {/* <span>{questionnaire.submitted}</span> */}
            {!isSubmitted && (
                <QuestionnaireForm
                    user={user}
                    questionnaire={questionnaire}
                    submission={submission}
                    focusedItem={focusedItem}
                    onSubmit={handleSubmit}
                    onSave={handleSaves}
                />
            )}
            {isSubmitted && <QuestionnaireSubmitted questionnaireType={questionnaire.type} />}
            {/* <QuestionnaireFormModal /> */}
        </>
    )
}

function getDefaultResponses(q: QuestionnaireData | undefined): Record<string, any> {
    const defaultResponsesData: Record<string, any> = {}

    if (q === undefined) return {}
    q.dynamic_data.forEach((section) => {
        section.data.forEach((item, num) => {
            const key = `${section.section}:${num + 1}`
            defaultResponsesData[key] = item.defaultValue
        })
    })

    return defaultResponsesData
}

function useSubmission(
    user: User | null,
    questionnaireId: number,
    questionnaire: QuestionnaireData | undefined,
): Submission | undefined {
    const { data } = useSWR(["/api/questionnaires/{qid}/submission", questionnaireId], (_, qid) =>
        server.getSubmission(qid),
    )

    // Case 1. Data is still loading
    if (user === null) return undefined
    if (data === undefined) return undefined
    if (questionnaire === undefined) return undefined
    const defaultResponses = getDefaultResponses(questionnaire)

    // Case 2. Data is loaded(data is object)
    if (data !== null) return { ...data, uploads: {} }

    // Case 3. Data is loaded but there's no previous submission(data is null), so create default data

    return {
        questionnaireId,
        username: user.username,
        respondentProfile: user.respondentProfile,
        testProfile: { ...DEFAULT_TEST_PROFILE },
        payload: { ...defaultResponses },
        uploads: {},
        proofImages: [],
        usageImages: [],
    }
}

function useQuestionnaire(questionnaireId: number): QuestionnaireData | undefined {
    const { data } = useSWR(["/api/questionnaires/{qid}", questionnaireId], (_, qid) =>
        server.getQuestionnaire(qid),
    )
    return data
}

function validate(s: Submission, q: QuestionnaireData, p: boolean): ValidationError | null {
    const errors: ValidationErrorDetail[] = []

    // q.dynamic_data.forEach((section) => {
    //     section.data.forEach((item, num) => {
    //         const key = `${section.section}:${num + 1}`
    //         if (section.section !== "B" && s.payload[key].scale === 0) {
    //             errors.push({
    //                 key,
    //                 message: `"${section.name}" 중 "${item.question}" 문항의 스케일을 선택해주세요.`,
    //             })
    //         }
    //     })
    // })

    const rpFields = [
        "birthyear",
        "height",
        "weight",
        "shoesSize",
        "topSize",
        "bottomSize",
        "activityFreq",
        "activityRange",
    ]
    if (
        rpFields.some(
            (field) =>
                isNaN((s.respondentProfile as any)[field] as number) ||
                (s.respondentProfile as any)[field] === 0,
        )
    ) {
        errors.push({ key: null, message: "응답자 프로파일의 모든 항목을 입력해주세요." })
    }

    const tpFields = [
        "wearStartedAt",
        "wearEndedAt",
        "wearDistance",
        "activityWalk",
        "activityRun",
        "activityMisc",
        "envAsphalt",
        "envTrack",
        "envRock",
        "envDirt",
        "envForest",
        "envMisc",
    ]

    if (!s.testProfile.wearPlace) {
        errors.push({ key: null, message: "테스트 프로파일의 착용 장소를 입력해주세요." })
    }

    if (tpFields.some((field) => isNaN((s.testProfile as any)[field] as number))) {
        errors.push({ key: null, message: "테스트 프로파일의 모든 항목을 입력해주세요." })
    }

    if (q.type === 1 && p === false) {
        errors.push({ key: null, message: "테스트 프로파일 합을 정확히 입력해주세요." })
    }

    if (s.testProfile.wearDistance <= 0) {
        errors.push({ key: null, message: "착용거리는 0이상이어야 합니다." })
    }

    return errors.length === 0
        ? null
        : {
              message: errors[0].message,
              errors,
          }
}
