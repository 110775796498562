import PermissionDialog from "./PermissionDialog"
import catImg1 from "../assets/images/cat_img_1.png"
import catImg2 from "../assets/images/cat_img_2.png"
import catImg3 from "../assets/images/cat_img_3.png"
import catImg4 from "../assets/images/cat_img_4.png"
import catImg5 from "../assets/images/cat_img_5.png"
import catImg6 from "../assets/images/cat_img_6.png"
import catImg7 from "../assets/images/cat_img_7.png"
import catImg8 from "../assets/images/cat_img_8.png"
import catImg9 from "../assets/images/cat_img_9.png"
import introShoes from "../assets/images/intro_shoes.png"
import transparent from "../assets/images/transparent.png"
import { selectUser } from "../features/userSlice"
import { Server } from "../server"
import { QuestionnaireType } from "../types"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useRef, useState } from "react"
import { useCookies } from "react-cookie"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { EffectCards, Pagination, Swiper as SwiperType } from "swiper"
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react"
import useSWR from "swr"

import "swiper/swiper.min.css"
//import 'swiper/modules/pagination/pagination.min.css'
//import "swiper/modules/effect-cards/effect-cards.min.css"

const server = Server.getServer()

const catImages = [catImg2, catImg1, catImg3, catImg4, catImg5, catImg6, catImg7, catImg8, catImg9]

interface SlideStyleProps {
    index: number
}

const colors = [
    ["#ADD83A", "#fff"],
    ["#F49849", "#fff"],
    ["#68C8EB", "#fff"],
    ["#fff", "#000"],
    ["#fff", "#000"],
    ["#fff", "#000"],
    ["#fff", "#000"],
    ["#fff", "#000"],
    ["#fff", "#000"],
]

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        wrap: {
            textAlign: "center",
        },
        title: {
            paddingTop: "2rem",
            textAlign: "center",
            lineHeight: "1.96rem",
            fontSize: "2.25rem",
            fontFamily: "Pretendard",
            fontWeight: "bold",
            letterSpacing: "-.5px",
        },
        sub_title: {
            fontSize: "1.1rem",
            fontWeight: "normal",
            letterSpacing: "-.3px",
        },
        swiperWrapper: {
            width: "100%",
            height: "21.88rem",
            marginTop: "1.4rem",
            overflow: "hidden",
            // transformStyle: 'none !important',
        },
        swiper: {
            // display: "inline-block",
            width: "90%",
            // maxWidth: "80%",
            height: "90%",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            perspective: "1200px",
        },
        swiperSlide: {
            transformOrigin: "top center",
            borderRadius: "1.875rem",
            textAlign: "center",
            "&[data-swiper-slide-index='0']": {
                "&::before": {
                    backgroundColor: "#ADD83A",
                },
                "& p": {
                    color: "#FFF",
                },
            },
            "&[data-swiper-slide-index='1']": {
                "&::before": {
                    backgroundColor: "#F49849",
                },
                "& p": {
                    color: "#FFF",
                },
            },
            "&[data-swiper-slide-index='2']": {
                "&::before": {
                    backgroundColor: "#68C8EB",
                },
                "& p": {
                    color: "#FFF",
                },
            },
            "&[data-swiper-slide-index='3']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&[data-swiper-slide-index='4']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&[data-swiper-slide-index='5']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&[data-swiper-slide-index='6']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&[data-swiper-slide-index='7']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&[data-swiper-slide-index='8']": {
                "&::before": {
                    backgroundColor: "#fff",
                },
            },
            "&::before": {
                content: "''",
                position: "absolute",
                left: 0,
                right: 0,
                top: "4.1875rem",
                bottom: "0",
                width: "100%",
                // boxShadow: "0px 20px 20px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                borderRadius: "1.875rem",
            },
            // '&:not(:first-child)': {
            //     position: 'relative',
            //     transform: 'scale(0.9) !important',
            // }
        },
        swiperSlideImg: {
            position: "absolute",
            left: "50%",
            bottom: "0",
            transform: "translateX(-50%)",
            display: "inline-block",
            width: "100%",
            height: "100%",
            maxWidth: "335px",
            maxHeight: "315px",
            zIndex: 9,
            objectFit: "cover",
        },
        swiperSlideTitle: {
            position: "absolute",
            zIndex: 10,
            left: "26px",
            bottom: "30px",
            fontSize: "1.25rem",
            fontFamily: "Pretendard",
            fontWeight: "bold",
            letterSpacing: "-.5px",
            color: "#000",
            "& span": {
                position: "absolute",
                marginTop: "-.3rem",
                marginLeft: ".15rem",
                padding: "3px 6px 0",
                borderRadius: "11px",
                backgroundColor: "#EF6156",
                fontSize: ".75rem",
                color: "#FFF",
            },
        },
        swiperSlideNum: {
            position: "absolute",
            zIndex: 10,
            right: "25px",
            bottom: "20px",
            fontSize: "2.18rem",
            fontFamily: "Pretendard",
            fontWeight: "bold",
            letterSpacing: "-.5px",
            color: "#DEDEDE",
        },
        swiperPagination: {
            position: "static",
            width: "100%",
            padding: "36px",
            color: "#999999",
            "& .swiper-pagination-bullet-active": {
                backgroundColor: "#EF6156",
            },
        },
        list: {
            display: "flex",
            margin: "1.25rem -0.375rem",
            flexWrap: "wrap",
            padding: "0",
            "& li": {
                width: "calc(50% - 0.75rem)",
                margin: "0.375rem",
                position: "relative",
                listStyle: "none",
                borderRadius: "0.625rem",
                textAlign: "center",
                backgroundColor: "#fff",
                boxShadow: "0.25rem 0.25rem 0.625rem rgba(0, 0, 0, 0.25)",
                "&:first-child": {
                    width: "100%",
                },
                "&.disabled:after": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "0.625rem",
                    opacity: "0.8",
                },
                "& a": {
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "0.625rem 1.25rem 0.938rem",
                    textDecoration: "none",
                },
                "& p": {
                    fontFamily: "Roboto",
                    fontWeight: 900,
                    fontSize: "1.5rem",
                    color: "#222",
                    textAlign: "left",
                },
                "& span": {
                    display: "block",
                    margin: "0 1px 0.625rem",
                    fontSize: "0.9375rem",
                    color: "#222",
                    textAlign: "left",
                    opacity: "0.5",
                },
                "& figure": {
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    "& img": {
                        maxWidth: "80%",
                    },
                },
            },
        },
    }),
)

export default function Intro(): React.ReactElement {
    const swiperRef = useRef<SwiperType | null>(null)
    const userInfo = (users: any) => {
        if (users.empnum) {
            return users.username + " - " + users.firstName
        } else {
            return users.username
        }
    }

    const classes = useStyles()
    const user = useSelector(selectUser)
    const questionnaireTypes = useQuestionnaireTypes()
    const [questionnaireArray, setQuestionnaireArray] = useState<any[]>()
    const [cookies] = useCookies(["group_status"])
    const [visibleSlide, setVisibleSlide] = useState(0)

    useEffect(() => {
        console.log("questionnaire type", questionnaireTypes)
        // const array = questionnaireTypes !== undefined ? [
        //     questionnaireTypes[0],
        //     questionnaireTypes[1],
        //     questionnaireTypes[2],
        //     questionnaireTypes[3],
        //     questionnaireTypes[4],
        //     questionnaireTypes[5],
        //     questionnaireTypes[6],
        //     questionnaireTypes[7],
        //     questionnaireTypes[8],] : undefined
        setQuestionnaireArray(questionnaireTypes)
    }, [questionnaireTypes])

    if (questionnaireTypes) {
        return !user || !questionnaireTypes ? (
            <></>
        ) : (
            <>
                <Typography component="h2" variant="h5" className={classes.title}>
                    안녕하세요!
                    <br />
                    <span className={classes.sub_title}>{user && userInfo(user)} 님</span>
                </Typography>
                <div className={classes.swiperWrapper}>
                    <Swiper
                        className={classes.swiper}
                        slidesPerView={"auto"}
                        direction={"vertical"}
                        effect="cards"
                        modules={[EffectCards, Pagination]}
                        cardsEffect={{
                            rotate: false,
                            slideShadows: false,
                            perSlideOffset: questionnaireTypes.length,
                        }}
                        pagination={{
                            el: ".swiper-pagination",
                            clickable: true,
                            verticalClass: "swiper-pagination-horizontal",
                        }}
                        loop={true}
                        onSlideChange={(e) => {
                            console.log("slide change", e)
                            setVisibleSlide(e.realIndex)
                        }}
                        // onSwiper={(swiper) => {
                        //     swiperRef.current = swiper
                        //     setVisibleSlide(swiper.visibleSlidesIndexes[0])
                        //     console.log('swiper ref', swiper.visibleSlidesIndexes[0])
                        // }}
                    >
                        {questionnaireArray &&
                            questionnaireArray.map((questionnaireType, index) => (
                                <SwiperSlide
                                    className={classes.swiperSlide}
                                    key={questionnaireType.id}
                                >
                                    <Link to={`/questionnaireList/${questionnaireType.id}`}>
                                        {/* <figure>
                                            <img className={classes.swiperSlideImg} src={catImages[index] || introShoes} alt="" />
                                        </figure> */}
                                        {index === visibleSlide && (
                                            <figure>
                                                <img
                                                    className={classes.swiperSlideImg}
                                                    src={catImages[index] || transparent}
                                                    alt=""
                                                />
                                            </figure>
                                        )}
                                        <Typography className={classes.swiperSlideTitle}>
                                            {questionnaireType.typeName}
                                            <span>{questionnaireType.count}</span>
                                        </Typography>
                                        <Typography className={classes.swiperSlideNum}>
                                            #{index + 1}
                                        </Typography>
                                    </Link>
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
                <div className={`${classes.swiperPagination} swiper-pagination`}></div>
                {!!user.empnum &&
                    cookies.group_status === undefined &&
                    user.tester &&
                    user.analyst && <PermissionDialog />}
            </>
        )
    }

    return <></>
}

function useQuestionnaireTypes(): QuestionnaireType[] | undefined {
    const { data } = useSWR("/api/get-type/", () => server.getQuestionnaireTypes())
    return data
}
