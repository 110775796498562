import ProductSummary from "./ProductSummary"
import ReportComment from "./ReportComment"
import ReportLikert from "./ReportLikert"
import ReportMarkOnImages from "./ReportMarkOnImages"
// import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
import {
    DefinitionItemLikert,
    DefinitionItemMarkOnImages,
    KVList,
    Questionnaire,
    // QUESTIONNAIRE_DEF,
    SubmissionRow,
    // DefinitionDynamicItemLikert,
} from "../../types"
// import { Button } from '@material-ui/core'
import FormControlLabel from "@material-ui/core/FormControlLabel"
// import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"
// import CreateIcon from "@material-ui/icons/Create"
import { ascending, nest } from "d3"
import React, { useEffect, useState } from "react"
// import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    questionnairereport: {
        padding: "0.625rem 1.5rem",
        marginTop: "5rem",
        backgroundColor: "#fff",

        "&:first-child": {
            marginTop: "0",
        },

        "& .key": {
            display: "flex",
            flexWrap: "nowrap",
            borderWidth: "1px 0 0 1px",
            borderStyle: "solid",
            borderColor: "#ECEBED",
            overflowX: "auto",

            "& .subkey": {
                minWidth: "220px",
                flex: "1 0 auto",
                padding: "1rem",
                borderWidth: "0 1px 1px 0",
                borderStyle: "solid",
                borderColor: "#ECEBED",
            },

            "@media print": {
                width: "auto",
                flexWrap: "wrap",
                pageBreakAfter: "always",

                "& .subkey": {
                    flex: "1 1 auto",
                },
            },
        },

        // "& .sticky": {
        //     // border: '5px solid black',
        //     "@media print": {
        //         position: 'relative'
        //     }
        //     // borderWidth: "1px 1px 0 1px",
        // },

        "&.oneproduct": {
            width: "830px",
            margin: "5rem auto 0",

            "& .subkey": {
                // width: "195px !important",
                height: "auto !important",
            },
        },
    },

    reporttop: {
        position: "relative",
        marginBottom: "2rem",
        display: "flex",
        justifyContent: "space-between",

        "& button": {
            position: "absolute",
            top: "0",
            right: "0",

            "@media print": {
                display: "none",
            },
        },
    },

    questionnaireitem: {
        marginBottom: "2rem",

        "& h3": {
            marginBottom: "0.625rem",
            fontWeight: "500",
        },
    },

    questionnaireitemHidden: {
        marginBottom: "2rem",

        "& h3": {
            marginBottom: "0.625rem",
            fontWeight: "500",
        },

        "@media print": {
            display: "none !important",
        },
    },

    emptyList: {
        margin: "8rem 1rem",
        textAlign: "center",
    },

    productSummary: {
        position: "sticky",
        top: "0px",
        zIndex: 5,
    },
    summaryBlock: {
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "nowrap",
        width: "100%",
        padding: "0.75rem 1rem 0.75rem 9rem",
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        // borderBottomColor: "#ccc",
        backgroundColor: "#f9f9f9",
        fontSize: "0.875rem",
        marginBottom: "1rem",
    },
    summaryChild: {
        flexShrink: 1,
        flexGrow: 0,
        flexBasis: "auto",
        whiteSpace: "nowrap",
        minWidth: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // border: '1px solid black'
    },
    summaryImageWrapper: {
        display: "flex",
        justifyContent: "center",
        padding: "5px",
    },
    summaryImage: {
        width: "70%",
        padding: 10,
    },
    sticky: {
        position: "sticky",
        top: "-1px",
        zIndex: 5,
        "@media print": {
            position: "static !important",
        },
        // "& .MuiTableHead-root": {
        //     "@media print": {
        //         border: '5px solid red',
        //         position: 'static !important'
        //     }
        // }
    },
    commentSwitch: {
        "@media print": {
            display: "none",
        },
    },
    stickyHeader: {
        position: "sticky",
        top: "-1px",
        zIndex: 5,
        "@media print": {
            position: "relative",
            top: 0,
        },
    },
})

interface Props {
    questionnaires: Questionnaire[]
    rows: SubmissionRow[]
}

export default function Comparison({ questionnaires, rows }: Props): React.ReactElement {
    const classes = useStyles()
    const [printComment, setPrintComment] = useState(true)

    const handlePrintComment = () => {
        setPrintComment(!printComment)
    }

    useEffect(() => {
        console.log(questionnaires)
        console.log(rows)
    }, [questionnaires, rows])

    if (rows.length === 0)
        return <div className={classes.emptyList}>제출된 설문 데이터가 없습니다</div>

    const rowGroups = nest<SubmissionRow>()
        .key((d) => d.category)
        .key((d) => d.key)
        .key((d) => d.questionnaireId)
        .entries(rows) as unknown as KVList<KVList<KVList<SubmissionRow>>>[]

    const payloadsGroup = rowGroups.find((row) => row.key === "payload")
    const payloads = payloadsGroup?.values || []
    payloads.sort((a, b) => ascending(+a.key, +b.key))

    return (
        <div
            className={`${classes.questionnairereport} ${
                questionnaires.length === 1 ? "oneproduct" : ""
            }`}
        >
            <div className={classes.reporttop}>
                <Typography component={"h2"} variant={"h4"}>
                    {questionnaires.length === 1
                        ? "개별 제품 평가 보고서"
                        : "제품 비교 평가 보고서"}
                </Typography>
                <FormControlLabel
                    className={classes.commentSwitch}
                    labelPlacement="start"
                    onClick={handlePrintComment}
                    control={<Switch checked={printComment} name="comment-switch" />}
                    label={printComment ? "코멘트 출력" : "코멘트 출력 안함"}
                />
            </div>
            <Typography component={"h3"} variant={"body1"} style={{ marginBottom: "5px" }}>
                제품 요약
            </Typography>
            <section className={`key sticky ${classes.stickyHeader}`}>
                <ProductSummary questionnaires={questionnaires} isSticky={true} />
            </section>
            <div className={classes.questionnaireitemHidden} style={{ marginTop: "10px" }}>
                <section className="key">
                    <ProductSummary questionnaires={questionnaires} isSticky={false} />
                </section>
            </div>
            {questionnaires[0]?.dynamic_data.map((arrData) => (
                <div key={arrData.id} className={classes.questionnaireitem}>
                    <Typography component={"h3"} variant={"body1"}>
                        {arrData.name}
                    </Typography>
                    <section className="key">
                        {arrData.data.map((item, idx) => {
                            const datas = Object.assign({
                                key: item.dynamic_id,
                                type: "likert",
                                question: item.question,
                                form: "long",
                                labels: [item.min_text, item.max_text],
                                defaultValue: item.defaultValue,
                                questionHelp: {
                                    helpImages: item.helpImages,
                                    helpText: item.helpText,
                                },
                            })
                            const key = String(idx + 1)

                            if (arrData.section != "B") {
                                const likertItem = datas as DefinitionItemLikert
                                const filterKey = `${arrData.section}:${key}`
                                const data = payloads.find((p) => p.key === filterKey)!.values
                                data.sort((a, b) => ascending(+a.key, +b.key))

                                return (
                                    <ReportLikert
                                        key={key}
                                        questionnaires={questionnaires}
                                        item={likertItem}
                                        data={data}
                                    />
                                )
                            } else if (arrData.section === "B") {
                                const images = [
                                    {
                                        key: "outerImage",
                                        label: "이미지1",
                                    },
                                    {
                                        key: "innerImage",
                                        label: "이미지2",
                                    },
                                    {
                                        key: "soleImage",
                                        label: "이미지3",
                                    },
                                ]
                                const datas = Object.assign({
                                    images: images,
                                    defaultValue: item.defaultValue,
                                })
                                const markOnImageItem = datas as DefinitionItemMarkOnImages
                                const filterKey = `${arrData.section}:${key}`
                                const data = payloads.filter((p) => p.key.startsWith(filterKey))
                                // 이미지에 통증부위 표시
                                return (
                                    <ReportMarkOnImages
                                        key={key}
                                        questionnaires={questionnaires}
                                        item={markOnImageItem}
                                        data={data}
                                    />
                                )
                            } else {
                                return <div key={item.dynamic_id}>UNKNOWN</div>
                            }
                        })}
                    </section>
                    {questionnaires.length !== 1 && arrData.section !== "B" && (
                        <ReportComment
                            arrData={arrData}
                            payloads={payloads}
                            questionnaires={questionnaires}
                            isCommentPrint={printComment}
                        />
                    )}
                </div>
            ))}
            {/* {QUESTIONNAIRE_DEF.sections
                .filter((section) => ["A", "B", "C", "D"].includes(section.key))
                .map((section) => (
                    <div key={section.key} className={classes.questionnaireitem}>
                        {console.log(QUESTIONNAIRE_DEF)}
                        <Typography component={"h3"} variant={"body1"}>
                            {section.name}
                        </Typography>
                        <section className="key">
                            {section.items.map((item) => {
                                if (item.type === "likert") {
                                    const likertItem = item as DefinitionItemLikert
                                    const filterKey = `${section.key}:${item.key}`
                                    const data = payloads.find((p) => p.key === filterKey)!.values
                                    data.sort((a, b) => ascending(+a.key, +b.key))
                                    const test2 = (
                                        <ReportLikert
                                            key={item.key}
                                            questionnaires={questionnaires}
                                            item={likertItem}
                                            data={data}
                                        />
                                    )
                                    return test2
                                } else if (item.type === "markOnImages") {
                                    const markOnImageItem = item as DefinitionItemMarkOnImages
                                    const filterKey = `${section.key}:${item.key}`
                                    const data = payloads.filter((p) => p.key.startsWith(filterKey))
                                    return (
                                        <ReportMarkOnImages
                                            key={item.key}
                                            questionnaires={questionnaires}
                                            item={markOnImageItem}
                                            data={data}
                                        />
                                    )
                                } else {
                                    return <div key={item.key}>UNKNOWN</div>
                                }
                            })}
                        </section>
                    </div>
                ))} */}
        </div>
    )
}
